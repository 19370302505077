import OptionSelect from "@/models/shared/OptionSelect"
import { translate } from "../plugins/i18n"

enum MovementStatus {
    Capture = 0
    , EndCapture
    , Approved
    , Cancelled
    , Precommitted
    , Committed
}

/**
 * Obtiene los valores del enumerador
 */
const possibleValues = Object.values(MovementStatus)
    .filter((v) => !isNaN(Number(v)))

function getOptions(): OptionSelect[] {
    return [
     {
            id: MovementStatus.Capture
            , name: translate("ENUMS.TRANSFER_STATUS.Capture")
        },
         {
            id: MovementStatus.EndCapture
            , name: translate("ENUMS.TRANSFER_STATUS.EndCapture")
        },
         {
            id: MovementStatus.Approved
            , name: translate("ENUMS.TRANSFER_STATUS.Approved")
        },
         {
            id: MovementStatus.Cancelled
            , name: translate("ENUMS.TRANSFER_STATUS.Cancelled")
        },
        {
            id: MovementStatus.Precommitted
            , name: translate("ENUMS.TRANSFER_STATUS.Precommitted")
        },
         {
            id: MovementStatus.Committed
            , name: translate("ENUMS.TRANSFER_STATUS.Committed")
        }
    ]
}
export { possibleValues, getOptions , MovementStatus}