import { MovementType } from '@/core/enums/movement-types';
import Modules from '@/store/enums/Modules';
import store from '@/store';
import { Action, Module, Mutation, MutationAction, VuexModule, getModule } from "vuex-module-decorators";
import TableWerehouseInputsState from '../../models/TableWerehouseInputsState';
import PaginationRequest from '@/models/general/PaginationRequest';
import { Mutations } from '@/store/commons/MutationsTable';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import { Actions } from '@/store/commons/ActionsTable';
import ReceptionRow from '@/models/receptions/ReceptionRow';
import FiltersReceptions from '@/models/receptions/filters/FilterReceptions';
import OptionSelect from '@/models/shared/OptionSelect';
import WerehouseInputRow from '@/models/weewhouse/WerehouseInputRow';
import FiltersInputs from '@/models/weewhouse/filters/FiltersInputs';
import WerehouseInputsService from '@/core/services/werehouse/WerehouseInputsService';
import CustomerComboService from '@/core/services/customer/CustomerComboService';
import { MovementStatus } from '@/core/enums/movement-status';
import MovementTypeComboService from '@/core/services/movementTypes/MovementTypeComboService';

enum MutationsOwner { 
    SET_SELECT_ROW = "SET_SELECT_ROW"
    , RESET_SELECT_ROW = "RESET_SELECT_ROW"
}
const defaultFilters = {
            endDate: ''
            , folioOC: ''
            , startDate: ''
            , customerIds: []
            , folio: ''
            , customerFolio: ''
            , movementTypeId: ''
            , relatedFolio: ''
            , status: ''
        } as FiltersInputs;
@Module({dynamic: true, store, namespaced: true, name: Modules.TableInputsModule})
class TableInputsModule extends VuexModule implements TableWerehouseInputsState {
    selects = {
        werehouseOptions: [] as OptionSelect[]
        , customerOptions: [] as OptionSelect[]
        , movementTypeOptions: [] as OptionSelect[]
    };
    rowSelected: WerehouseInputRow = defaultRow;
    records: WerehouseInputRow[] = [];
    totalRecords = 0;
    pagination = {
        currentPage: 1
        , pageSize: 10
    } as PaginationRequest;
    filters = defaultFilters;
    loading = false;
   

    get getFilters(): FiltersInputs { 
        return this.filters;
    }

    get getSelectedRow(): WerehouseInputRow { 
        return this.rowSelected;
    }
    get getRecordsTable(): WerehouseInputRow[] { 
        return this.records;
    }
    get getRowSelected(): WerehouseInputRow { 
        return this.rowSelected;
    }
    get thisFinalCapture(): boolean { 
        return this.rowSelected.status == MovementStatus.EndCapture;
    }

    @Mutation
    [MutationsOwner.SET_SELECT_ROW](value: WerehouseInputRow | undefined) {
        if (value) { 
            this.rowSelected = value;
        }
    }
    @Mutation
    [MutationsOwner.RESET_SELECT_ROW](){
        this.rowSelected = defaultRow;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: WerehouseInputRow[]){
        this.records = table;
    }
     @Mutation
    [Mutations.RESET_FILTERS](){
         this.filters = defaultFilters;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: Partial<PaginationRequest>){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: Partial<FiltersReceptions>) {
        //reseteamos los filtros y volvemos a asignar
        this.filters = defaultFilters;
        SaveValuesToStore(this.filters, filters, true);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

     /**
     * Se obtienen los datos de la tabla de facturas creadas mediante la paginación
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
         
         const resp = (await (service.searchTableInputs(this.pagination, this.filters)
             .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ??
             {
                 data: [] as ReceptionRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
         }
        // const data = resp.data.map(r => { return {...r, createdAt: } })
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);
    }
 
     /**
        * Realiza un busqueda con la nueva paginación
        * @param pagination paginación de la tabla
        */
     @Action
     async [Actions.CHANGE_PAGE](pagination: Partial<PaginationRequest>){
           this.context.commit(Mutations.SET_PAGINATION, pagination);
           this.context.dispatch(Actions.SEARCH_TABLE_DATA);
     }
 
     /**
      * Filtros que se aplican y despues se hace la busqueda de los datos
      * @param filters filtros nuevos
      */
     @Action
     async [Actions.UPDATE_FILTERS](filters: Partial<FiltersReceptions>) {
         //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
         this.context.commit(Mutations.SET_FILTERS, filters);
         this.context.commit(Mutations.SET_PAGINATION, {
           currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
         });
         this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    
     @MutationAction
     async getComboCustomer(filterName: string) {
         const comboOptions = await customerService.searchCombo(filterName);
         const selects = { ...this.selects }
        if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, customerOptions: comboOptions.data ?? [] } }
    }
     @MutationAction
     async getComboMovementType(filterName: string) {
         const comboOptions = await movementService.searchCombo(MovementType.Inputs,filterName);
         const selects = { ...this.selects }
        //if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, movementTypeOptions: comboOptions.data ?? [] } }
    }
     @Action
     async searchInfoInputSelected(id: string) {
         return service.searchInputInfo(id)
             .then((reception) => { 
                this.context.commit(MutationsOwner.SET_SELECT_ROW, reception.data ?? defaultRow);

             })
    }
} 

const service = new WerehouseInputsService();
const customerService = new CustomerComboService()
const movementService = new MovementTypeComboService();
const defaultRow = {
    id: '',
    createdAt: undefined,
    supplierId: '',
    supplierName: '',
    folio: '',
    movementTypeId: '',
    movementTypeName: '',
    status: 0,
    statusName: '',
    total: 0,
    warehouseId: '',
    relatedFolio: '',
    isAutomaticMovement: false
} as WerehouseInputRow;

export default getModule(TableInputsModule);
