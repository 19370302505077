
import { computed, defineComponent, ref, watch } from "vue"
import { useField } from "vee-validate";

export default defineComponent({
    props:{
        nameDateStart: {
            type: String
            , default: "startDate"
        } 
        , nameDateEnd: {
            type: String
            , default: "endDate"
        }
        , placehoderDateStart: {
            type: String
            , default: ""
        } 
        , placehoderDateEnd: {
            type: String
            , defaut: ""
        },
        disabled: {
            type: Boolean
            , default: false
        }
    },
    setup(props){
        const fieldStartDate = useField<string>(props.nameDateStart);
        const fieldEndDate = useField<string>(props.nameDateEnd);
        const datesData = ref([] as Date[]);
        

        const errorMessage = computed(() => {
            let error = (fieldStartDate.errorMessage.value ? (fieldStartDate.errorMessage.value ): '') + fieldEndDate.errorMessage.value ?? '';
            return error;
        });

        watch(datesData, (currentValue) => {
            if (currentValue && currentValue[0] && currentValue[1]) {
                fieldStartDate.setValue(currentValue[0].toISOString());
                fieldEndDate.setValue(currentValue[1].toISOString());
            } else { 
                fieldStartDate.resetField();
                fieldEndDate.resetField();
            }
        });
        watch(fieldStartDate.value, (currentValue) => {
            if(!currentValue || currentValue === undefined){
                datesData.value = [];
            } else {
                datesData.value = [ new Date(currentValue), new Date(fieldEndDate.value.value ?? '')];
            }
        });
        return {
            datesData
            , fieldStartDate
            , fieldEndDate
            , errorMessage
            , error: fieldStartDate.errorMessage//por mientras solo este error
        }
    }
})
