import OptionSelect from "@/models/shared/OptionSelect";
import { translate } from "../plugins/i18n";

/**
 * Tipos de entradas
 */
 export enum InputTypes {
    Capture = 0
    , EndCapture
}


/**
 * Opciones según el enumerador
 * @returns lISTA DE OPCIONES
 */
export function getOptions(): OptionSelect[] {
    return [
        {
            id: InputTypes.Capture
            , name: translate("ENUMS.INPUTS_TYPES.Capture")
        },
        {
            id: InputTypes.EndCapture
            , name: translate("ENUMS.INPUTS_TYPES.EndCapture")
        }
    ]
}