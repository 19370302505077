export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "ADVANCE_SERACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda avanzada"])},
          "FOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio Entrada"])},
          "FOLIO_OC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio Orden de compra"])},
          "NAME_SUPPLIERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientes"])},
          "DATE_DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de entrega"])},
          "FOLIO_CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio Proveedor"])},
          "TYPE_ENTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de entrada"])},
          "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatus"])}
        },
        "BUTTONS": {
          "CLEAN_FILTERS_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar"])},
          "SEARCH_INPUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda de entradas"])}
        }
      }
    }
  })
}
