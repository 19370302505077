export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "MOVEMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de entrada"])}
        }
      }
    }
  })
}
