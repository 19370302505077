import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_el_form_item, { error: _ctx.error }, {
      default: _withCtx(() => [
        _createVNode(_component_el_date_picker, {
          modelValue: _ctx.datesData,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.datesData) = $event)),
          class: "w-100",
          type: "daterange",
          "range-separator": "-",
          format: "DD/MM/YYYY",
          "start-placeholder": _ctx.placehoderDateStart,
          "end-placeholder": _ctx.placehoderDateEnd,
          disabled: _ctx.disabled
        }, null, 8, ["modelValue", "start-placeholder", "end-placeholder", "disabled"])
      ]),
      _: 1
    }, 8, ["error"])
  ]))
}